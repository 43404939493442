/*antd font-weight input field*/
.ant-input::-webkit-input-placeholder {
    font-weight: 600;
}
.ant-input::-moz-placeholder {
    font-weight: 600;
}
.ant-input::-ms-input-placeholder {
    font-weight: 600;
}

.login-button {
    margin-bottom: 8px;
}
