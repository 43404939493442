@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow: hidden;
}

/* Override base SVG style to work with Ant Design */
svg {
    vertical-align: initial;
  }
  